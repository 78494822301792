import { type ClientInit } from '@sveltejs/kit';
import { iocRegistry } from 'ioc/IocRegistry';

const { monitoringClient } = iocRegistry;

export const init: ClientInit = () => {
	monitoringClient.initialize();
};

export const handleError = monitoringClient.createHandleError();
